import React, { useMemo } from 'react';

import { useAppSelector } from '../../app/hooks';
import { RoomCardEditModal } from './RoomCardEditModal';
import { RoomCardsGrid } from './RoomCardsGrid';
import { RoomCardsList } from './RoomCardsList';
import { roomCardsSelectors } from './roomCardsSlice';

// 방 목록을 보여준다. 옵션에 따라 보여주는 방식이 변화.
export function RoomCards() {
  const { alignment } = useAppSelector(roomCardsSelectors.selectState);
  const isGridAlignment = useMemo(() => {
    return alignment !== 'list';
  }, [alignment]);

  // FIXME: 추후에는 grid<->list를 통합하여 transition 해도 될 것 같다.

  return (
    <div>
      {isGridAlignment ? <RoomCardsGrid /> : <RoomCardsList />}
      <RoomCardEditModal />
    </div>
  );
}
