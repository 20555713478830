// "{"type":"DataMessage","signalStatus":"0","zone":"01","userNumber":"0001","mainDeviceNumber":"01","extensionNumber":"00","signal":"21","createdAt":"2023-06-26T13:10:32Z","id":"0001010001"}"
import { EventMessage } from './EventMessage';
import { SensorStatus } from './SensorStatus';

export interface DataMessage extends EventMessage {
  type: 'DataMessage';
  id: string;
  userNumber: string;
  mainDeviceNumber: string;
  extensionNumber: string;
  signal: SensorStatus;
  createdAt: string;
}

export function isDataMessage(event: EventMessage): event is DataMessage {
  return event.type === 'DataMessage';
}
