import React, { useEffect } from 'react';

import Typography from '@mui/material/Typography';

import { format } from 'date-fns';
import { ko } from 'date-fns/locale';

export function TimeDisplay() {
  const [currentTime, setCurrentTime] = React.useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Typography
      variant="h6"
      noWrap
      textAlign={'center'}
      sx={{ color: '#3d3d3d', fontSize: 20 }}
    >
      {format(currentTime, 'MMMM do eeee HH:mm', { locale: ko })}
    </Typography>
  );
}
