import React, { ComponentProps, useCallback, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { formatDistanceToNow, parseISO } from 'date-fns';
import ko from 'date-fns/locale/ko';

export interface ShowEventHistoryButtonProps
  extends ComponentProps<typeof Button> {
  lastUpdatedAt: string; // timestamp
}

export function ShowEventHistoryButton({
  lastUpdatedAt,
  ...props
}: ShowEventHistoryButtonProps) {
  const [relativeTimeText, setRelativeTimeText] = useState<string | null>(null);
  const handleLastUpdatedAtChange = useCallback(() => {
    const relativeTimeText = formatDistanceToNow(parseISO(lastUpdatedAt), {
      addSuffix: true,
      locale: ko,
    });
    setRelativeTimeText(relativeTimeText);
  }, [lastUpdatedAt]);
  useEffect(() => {
    handleLastUpdatedAtChange();
    const timer = window.setInterval(() => {
      handleLastUpdatedAtChange();
    }, 10000);

    return () => {
      window.clearInterval(timer);
    };
  }, [handleLastUpdatedAtChange]);

  return relativeTimeText ? (
    <Button
      variant="outlined"
      sx={{
        boxShadow: '0 2px 4px 0 rgba(227, 227, 227, 0.5)',
        border: 'solid 0.5px #e3e3e3',
        borderRadius: '5px',
        height: 35,
        backgroundColor: '#fff',
      }}
      {...props}
    >
      <Typography variant="button" display="block" color={'#444'}>
        <span style={{ marginRight: '4px' }}>🔔</span>
        <b>{relativeTimeText}</b>
        <span>에 업데이트 되었습니다.</span>
      </Typography>
    </Button>
  ) : null;
}
