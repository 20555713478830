import React from 'react';

import Box from '@mui/material/Box';

import { DotButton } from './DotButton';

export interface DotButtonPaginationProps {
  pageSize: number;
  currentPage: number;
  onClick?: (index: number) => void;
}
export function DotButtonPagination({
  pageSize,
  onClick,
}: DotButtonPaginationProps) {
  return (
    <Box sx={{ display: 'inline-block' }}>
      {Array.from({ length: pageSize }).map((_, index) => {
        return (
          <DotButton
            key={index}
            onClick={onClick ? () => onClick(index) : undefined}
          />
        );
      })}
    </Box>
  );
}
