import React from 'react';

import { styled } from '@mui/material/styles';

const StyledSpan = styled('span')(() => ({
  height: 15,
  width: 15,
  backgroundColor: '#646464',
  borderRadius: '50%',
  display: 'inline-block',
  margin: 10,
  cursor: 'pointer',
}));

export interface DotButtonProps {
  onClick?: () => void;
}
export function DotButton({ onClick }: DotButtonProps) {
  return <StyledSpan onClick={onClick} />;
}
