import { addMinutes } from 'date-fns';
import { rest } from 'msw';

import { RoomCardInfo } from '../types/RoomCardInfo';
import { RoomHistoryItem } from '../types/RoomHistoryItem';
import { RoomStatus } from '../types/RoomStatus';

const RoomCardsMockState: RoomCardInfo[] = [
  {
    id: '1',
    name: '홍길동',
    position: '의원',
    status: {
      id: '1',
      description: '퇴실',
      color: 'red',
    },
    memo: '테스트 중',
  },
  {
    id: '2',
    name: '홍길동2',
    position: '의원',
    status: {
      id: '1',
      description: '재실',
      color: 'blue',
    },
  },
  {
    id: '3',
    name: '홍길동3',
    position: '의원',
    status: {
      id: '1',
      description: '퇴실',
      color: 'red',
    },
    memo: '테스트 중',
  },
  {
    id: '4',
    name: '홍길동4',
    position: '의원',
    status: {
      id: '1',
      description: '재실',
      color: 'blue',
    },
  },
  {
    id: '5',
    name: '홍길동5',
    position: '의원',
    status: {
      id: '2',
      description: '퇴실',
      color: 'red',
    },
    memo: '테스트 중',
  },
  {
    id: '6',
    name: '홍길동6',
    position: '의원',
    status: {
      id: '1',
      description: '재실',
      color: 'blue',
    },
  },
  {
    id: '7',
    name: '홍길동7',
    position: '의원',
    status: {
      id: '1',
      description: '퇴실',
      color: 'red',
    },
    memo: '테스트 중',
  },
  {
    id: '8',
    name: '홍길동8',
    position: '의원',
    status: {
      id: '1',
      description: '재실',
      color: 'blue',
    },
  },
];

export const handlers = [
  rest.get('/api/mock/companyStats', (req, res, ctx) => {
    return res(
      ctx.json({
        company: {
          id: '1',
          name: '테스트 의회',
        },
        statuses: [
          {
            status: {
              id: '1',
              description: '재실',
              color: 'blue',
            },
            count: 6,
          },
          {
            status: {
              id: '2',
              description: '퇴실',
              color: 'gray',
            },
            count: 0,
          },
          {
            status: {
              id: '3',
              description: '회의중',
              color: 'green',
            },
            count: 6,
          },
          {
            status: {
              id: '4',
              description: '출장중',
              color: '',
            },
            count: 2,
          },
        ],
      }),
    );
  }),
  rest.get('/api/mock/roomCardInfos', (req, res, ctx) => {
    return res(
      ctx.json(
        RoomCardsMockState.map((d) => ({
          ...d,
          extraStatuses: [
            {
              id: '1',
              description: '수동모드 퇴실',
              status: Math.random() > 0.5,
            },
            {
              id: '2',
              description: '수동모드 재실',
              status: Math.random() > 0.5,
            },
            {
              id: '3',
              description: '자동모드',
              status: Math.random() > 0.5,
            },
          ],
        })),
      ),
    );
  }),
  rest.get('/api/mock/companyEventHistories', (req, res, ctx) => {
    let fromStatus: RoomStatus = {
      id: '1',
      description: '재실',
      color: 'blue',
    };
    let toStatus: RoomStatus = {
      id: '2',
      description: '퇴실',
      color: 'red',
    };
    const now = new Date();
    const historyItems = Array(10)
      .fill(0)
      .map((d, i) => {
        const item: RoomHistoryItem = {
          id: `${i}`,
          room: {
            id: `${i}`,
            name: `홍길동${i}`,
            position: '의원',
          },
          fromStatus,
          toStatus,
          createdAt: addMinutes(
            now,
            -i * Math.round(Math.random() * 10),
          ).toISOString(),
        };

        let tempStatus = toStatus;
        toStatus = fromStatus;
        fromStatus = tempStatus;
        return item;
      });
    return res(ctx.json(historyItems));
  }),
  rest.get('/api/mock/roomCardHistories/:id', (req, res, ctx) => {
    let fromStatus: RoomStatus = {
      id: '1',
      description: '재실',
      color: 'blue',
    };
    let toStatus: RoomStatus = {
      id: '2',
      description: '퇴실',
      color: 'red',
    };
    const _id = req.params.id;
    const room =
      RoomCardsMockState.find((d) => d.id === _id) || RoomCardsMockState[0];
    const now = new Date();
    const historyItems = Array(3)
      .fill(0)
      .map((d, i) => {
        const item: RoomHistoryItem = {
          id: `${i}`,
          room,
          fromStatus,
          toStatus,
          createdAt: addMinutes(
            now,
            -i * Math.round(Math.random() * 10),
          ).toISOString(),
        };

        let tempStatus = toStatus;
        toStatus = fromStatus;
        fromStatus = tempStatus;
        return item;
      });
    return res(ctx.json(historyItems));
  }),
  rest.post('/api/mock/roomCardExtraStatuses/:id', (req, res, ctx) => {
    return res(ctx.json(req.json()));
  }),
];
