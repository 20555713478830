import React, { HTMLProps, useMemo } from 'react';

import { Dictionary } from '@reduxjs/toolkit';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid, { GridProps } from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

import {
  differenceInHours,
  format,
  formatDistanceToNow,
  parseISO,
} from 'date-fns';
import ko from 'date-fns/locale/ko';

import { Room } from '../types/Room';
import { SensorDevice } from '../types/SensorDevice';
import { SensorDeviceHistory } from '../types/SensorDeviceHistory';
import { SensorStatus } from '../types/SensorStatus';
import { SensorStatusChip } from './SensorStatusChip';

export interface HistoryTableProps {
  items: SensorDeviceHistory[];
  user?: Room | null;
  nameMap?: Dictionary<SensorDevice>;
  onSeeMore?: () => void;
  isShowMore?: boolean;
  isForEditModal?: boolean;
}
const DivInlineBlock = ({ children, ...props }: HTMLProps<HTMLDivElement>) => {
  return (
    <div {...props} style={{ display: 'inline-block', ...props.style }}>
      {children}
    </div>
  );
};

const StyledTableCellItem = styled(Box)(() => ({}));

function HistoryTableCell({ children, ...props }: GridProps) {
  return (
    <Grid item={true} {...props}>
      <StyledTableCellItem>{children}</StyledTableCellItem>
    </Grid>
  );
}

// const HistoryTableCell = styled(Box)(() => ({
//   // display: 'inline-block',
//   textAlign: 'center',
// }));

function StatusRow({
  item,
  user,
  nameMap,
  isForEditModal,
}: {
  item: SensorDeviceHistory;
  user?: Room | null;
  nameMap?: Dictionary<SensorDevice>;
  isForEditModal?: boolean;
}) {
  const date = parseISO(item.createdAt);
  const absoluteDate = format(date, 'yyyy-MM-dd HH:mm:ss');
  const relativeDate =
    differenceInHours(new Date(), date) < 1
      ? formatDistanceToNow(date, {
          addSuffix: true,
          locale: ko,
        })
      : undefined;
  const name = `${
    user?.name || nameMap?.[item.sensorDeviceId]?.companyUser?.name
  } ${user?.position || nameMap?.[item.sensorDeviceId]?.companyUser?.position}`;
  const isMemo = useMemo(() => item.historyType === 'MEMO', [item]);

  return (
    <Grid
      container={true}
      item={true}
      columns={{ xs: 6, sm: 6, md: 12 }}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{
        padding: '17px 19px',
        borderBottom: 'solid 1px #d1d1d1',
        '&:first-child': isForEditModal
          ? undefined
          : { borderTop: 'solid 1px #d1d1d1' },
        backgroundColor: 'transparent',
      }}
      spacing={0.5}
      //
      // useFlexGap={true}
      // flexWrap={'wrap'}
    >
      <HistoryTableCell
        sx={{
          fontSize: '16px',
          fontWeight: 'bold',
          textAlign: 'left',
        }}
        xs={6}
        sm={6}
        md={2}
      >
        {isForEditModal ? (isMemo ? '메모' : '상태변경') : name}
      </HistoryTableCell>
      {isMemo ? (
        <HistoryTableCell xs={6} sm={6} md={4}>
          {item.memo || ''}
        </HistoryTableCell>
      ) : (
        <>
          <HistoryTableCell xs={1} sm={1} md={1}>
            <DivInlineBlock>{item?.auto ? '(자동)' : '(수동)'}</DivInlineBlock>
          </HistoryTableCell>
          <HistoryTableCell xs={5} sm={5} md={3}>
            <Grid container item xs={5} columns={{ x: 12 }}>
              <Grid item xs={5}>
                <SensorStatusChip
                  maxWidth={84}
                  status={item.previousStatus as SensorStatus}
                />
              </Grid>
              <Grid item xs={2} padding={'0 10px'}>
                {'>'}
              </Grid>
              <Grid item xs={5}>
                <SensorStatusChip
                  maxWidth={84}
                  status={item.currentStatus as SensorStatus}
                />
              </Grid>
            </Grid>
          </HistoryTableCell>
        </>
      )}

      <HistoryTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        xs={1.5}
        sm={1.5}
        md={1.5}
      >
        {item.createdBy || item.updatedBy}
      </HistoryTableCell>
      <HistoryTableCell
        xs={4.5}
        sm={4.5}
        md={4.5}
        sx={{ textAlign: 'right' }}
      >{`${absoluteDate}${
        relativeDate ? `(${relativeDate})` : ''
      }`}</HistoryTableCell>
    </Grid>
  );
}

export function HistoryTable({
  user,
  items,
  nameMap,
  isForEditModal,
  isShowMore,
  onSeeMore,
}: HistoryTableProps) {
  return (
    <Grid spacing={0} sx={{ fontSize: '14px', color: '#333' }}>
      {items.map((history) => {
        return (
          <StatusRow
            key={history.id}
            isForEditModal={isForEditModal}
            item={history}
            user={user}
            nameMap={nameMap}
          />
        );
      })}
      {isShowMore ? (
        <Grid
          container={true}
          item={true}
          sx={{ padding: '13px 0', borderBottom: 'none' }}
          justifyContent={'center'}
        >
          <Grid item>
            <Button
              variant="text"
              size={'small'}
              onClick={onSeeMore}
              sx={{
                fontSize: '14px',
                color: '#333',
                textAlign: 'center',
              }}
            >
              {'더보기 >'}
            </Button>
          </Grid>
        </Grid>
      ) : null}
    </Grid>
  );
}
