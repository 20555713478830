import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import '@mui/material';
import { DialogContentText } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  sensorDevicesSelectors,
  useGetHomeQuery,
  useGetSensorDeviceHistoriesMutation,
  usePutSensorDevicesMutation,
} from '../../app/services/api';
import { CommonDialog } from '../../components/CommonDialog';
import { CommonTextField } from '../../components/CommonTextField';
import { DialogTitleWithCloseButton } from '../../components/DialogTitleWithCloseButton';
import { HistoryTable } from '../../components/HistoryTable';
import { sensorStatusKeys } from '../../configs/sensorStatusKeys';
import { sensorStatusLabels } from '../../configs/sensorStatusLables';
import { useIsAdminOrManagerMode } from '../../hooks/useIsAdminOrManagerMode';
import { SensorDeviceHistory } from '../../types/SensorDeviceHistory';
import { SensorStatus } from '../../types/SensorStatus';
import { companySlice } from '../Company/companySlice';
import { roomCardsSelectors, roomCardsSlice } from './roomCardsSlice';

const autoStatusKey = '@@auto@@' as const;
type ExtraStatus = SensorStatus | typeof autoStatusKey | string;

export function RoomCardEditModal() {
  const dispatch = useAppDispatch();
  const { isEditModalOpen, selectedCardId } = useAppSelector(
    roomCardsSelectors.selectState,
  );
  const isAdminMode = useIsAdminOrManagerMode();
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const { data } = useGetHomeQuery();
  const [getSensorDeviceHistories, { data: historyItems }] =
    useGetSensorDeviceHistoriesMutation();
  const [totalHistoryItems, setTotalHistoryItems] = useState<
    SensorDeviceHistory[]
  >([]);
  const [
    putSensorDevices,
    {
      isLoading: isPutSensorDevicesLoading,
      isSuccess: isPutSensorDevicesSuccess,
    },
  ] = usePutSensorDevicesMutation();
  const cardInfo = useMemo(() => {
    return data && selectedCardId
      ? sensorDevicesSelectors.selectById(data, selectedCardId)
      : undefined;
  }, [data, selectedCardId]);

  const originalExtraStatusAndMemo = useMemo(() => {
    const originalExtraStatus = cardInfo
      ? ((cardInfo.auto ? autoStatusKey : cardInfo.status) as ExtraStatus)
      : autoStatusKey;
    const originalMemo = cardInfo?.memo || '';
    return { originalExtraStatus, originalMemo };
  }, [cardInfo]);

  const [extraStatus, setExtraStatus] = React.useState<ExtraStatus>(
    originalExtraStatusAndMemo.originalExtraStatus,
  );
  const [memo, setMemo] = useState<string>(
    originalExtraStatusAndMemo.originalMemo || '',
  );
  const isMemoOverflow = useMemo(() => {
    return memo.length > 32;
  }, [memo]);
  const isOpen = useMemo(() => {
    return Boolean(isEditModalOpen && selectedCardId && cardInfo);
  }, [isEditModalOpen, selectedCardId, cardInfo]);
  const isStatusEdited = useMemo(() => {
    return extraStatus !== originalExtraStatusAndMemo.originalExtraStatus;
  }, [extraStatus, originalExtraStatusAndMemo]);
  const isMemoEdited = useMemo(() => {
    return originalExtraStatusAndMemo.originalMemo !== memo;
  }, [originalExtraStatusAndMemo, memo]);
  const isEdited = useMemo(() => {
    return isStatusEdited || isMemoEdited;
  }, [isMemoEdited, isStatusEdited]);
  const handleClose = useCallback(() => {
    setIsAlertModalOpen(false);
    dispatch(roomCardsSlice.actions.setIsEditModalOpen(false));
  }, [dispatch]);
  const handleModalClose = useCallback(() => {
    if (isEdited) {
      setIsAlertModalOpen(true);
    } else {
      handleClose();
    }
  }, [isEdited, handleClose]);
  const handleAlertModalClose = useCallback(() => {
    setIsAlertModalOpen(false);
  }, []);
  const handleExtraStatusesSave = useCallback(() => {
    if (selectedCardId && cardInfo) {
      putSensorDevices({
        id: selectedCardId,
        auto:
          isStatusEdited && extraStatus === autoStatusKey ? true : undefined,
        status:
          isStatusEdited && extraStatus !== autoStatusKey
            ? extraStatus
            : undefined,
        memo: isMemoEdited ? memo : undefined,
      });
    }
  }, [
    selectedCardId,
    cardInfo,
    extraStatus,
    putSensorDevices,
    memo,
    isStatusEdited,
    isMemoEdited,
  ]);
  const handleSeeMore = useCallback(() => {
    dispatch(companySlice.actions.setIsHistoryModalOpen(true));
    dispatch(roomCardsSlice.actions.setIsEditModalOpen(false));
  }, [dispatch]);
  const handleExtraStatusChange = useCallback(
    (event: React.ChangeEvent, value: string) => {
      setExtraStatus(value as ExtraStatus);
    },
    [],
  );
  const handleGetHistories = useCallback(() => {
    return getSensorDeviceHistories({
      id: selectedCardId,
      page: 0,
      size: 3,
      sensorHistorySearchType: 'ALL',
    });
  }, [getSensorDeviceHistories, selectedCardId]);
  const handleSnackbarClose = useCallback(() => {
    setIsSnackbarOpen(false);
  }, []);

  const handleMemoChange = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >((event) => {
    event.stopPropagation();
    setMemo(event.target.value);
  }, []);
  useLayoutEffect(() => {
    if (historyItems) setTotalHistoryItems(historyItems);
  }, [historyItems]);
  useEffect(() => {
    if (isEditModalOpen) {
      setTotalHistoryItems([]);
    }
  }, [isEditModalOpen]);
  useEffect(() => {
    if (isEditModalOpen && selectedCardId) {
      handleGetHistories();
    }
  }, [
    isEditModalOpen,
    selectedCardId,
    getSensorDeviceHistories,
    handleGetHistories,
  ]);
  useEffect(() => {
    setExtraStatus(originalExtraStatusAndMemo.originalExtraStatus);
  }, [originalExtraStatusAndMemo]);

  useEffect(() => {
    if (isPutSensorDevicesSuccess) handleGetHistories();
  }, [isPutSensorDevicesSuccess, handleGetHistories]);

  useEffect(() => {
    setIsSnackbarOpen(isPutSensorDevicesSuccess);
  }, [isPutSensorDevicesSuccess]);

  useEffect(() => {
    setMemo(originalExtraStatusAndMemo.originalMemo);
  }, [originalExtraStatusAndMemo]);

  useEffect(() => {
    if (!isOpen) {
      //dispatch(roomCardsSlice.actions.setSelectedCardId(undefined));
    }
  }, [isOpen]);

  return (
    <CommonDialog isMaxHeight={true} open={isOpen} onClose={handleModalClose}>
      <DialogTitleWithCloseButton onClose={handleModalClose}>
        {`${cardInfo?.companyUser?.name} ${cardInfo?.companyUser?.position} 룸 정보`}
      </DialogTitleWithCloseButton>
      <DialogContent
        sx={{
          display: 'relative',
          overflowY: 'scroll',
          '-webkit-overflow-scrolling': 'auto',
          '&::-webkit-scrollbar': {
            '-webkit-appearance': 'none',
            width: '12px !important',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0, 0, 0, .5)',
            borderRadius: '10px',
            border: '2px solid #ffffff',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#ffffff',
            borderRadius: '10px',
          },
        }}
      >
        <Box
          sx={{
            padding: '10px 14px 0 14px',
            marginBottom: '21px',
            backgroundColor: '#f7f8fa',
            border: 'none',
          }}
        >
          {totalHistoryItems && totalHistoryItems.length > 0 ? (
            <HistoryTable
              isForEditModal={true}
              items={totalHistoryItems}
              user={cardInfo?.companyUser}
              isShowMore={totalHistoryItems.length > 0}
              onSeeMore={handleSeeMore}
            />
          ) : (
            <Box
              height={196}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
            >
              <Typography variant={'body1'} textAlign={'center'} flexGrow={1}>
                룸 상태 변경 히스토리가 없습니다.
              </Typography>
            </Box>
          )}
        </Box>
        <CommonTextField
          fullWidth
          disabled={!isAdminMode}
          error={isMemoOverflow}
          helperText={
            isMemoOverflow ? '최대 입력글자수를 초과하였습니다.' : undefined
          }
          placeholder={'한 줄 메모'}
          variant={'outlined'}
          value={memo}
          autoComplete={'off'}
          onChange={handleMemoChange}
        />
        {isAdminMode ? (
          <>
            <Typography
              variant={'body2'}
              component={'p'}
              marginTop={'14px'}
              fontSize={'12px'}
              color={'#333'}
            >
              * 메모 입력시 대시보드 룸 이름 하단에 메세지가 노출됩니다. 입력 후
              하단 저장 버튼을 눌러주세요.
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              marginTop={'27px'}
              alignItems={'baseline'}
              paddingBottom={'7px'}
            >
              <Typography
                variant="h5"
                component="h2"
                fontSize={'18px'}
                fontWeight={'bold'}
                color={'#333'}
              >
                룸 상태
              </Typography>
              {isStatusEdited ? (
                <Typography
                  variant={'body2'}
                  component={'p'}
                  fontSize={'12px'}
                  color={'#ff0202'}
                >
                  룸 상태값이 변경되었습니다. 저장버튼을 눌러주세요.
                </Typography>
              ) : null}
            </Stack>
            <Divider
              sx={{ border: 'solid 1px #d1d1d1', marginBottom: '4px' }}
            />
            <FormGroup sx={{ fontSize: '14px', color: '#333' }}>
              <FormControl>
                <RadioGroup
                  value={extraStatus}
                  onChange={handleExtraStatusChange}
                >
                  <Grid container alignItems={'baseline'}>
                    <Grid item>
                      <FormControlLabel
                        sx={{ fontSize: '14px' }}
                        value={autoStatusKey}
                        control={<Radio />}
                        label={'자동 재실/퇴실 체크'}
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant={'body2'}
                        component={'div'}
                        fontSize={'12px'}
                        color={'#9c9c9c'}
                      >
                        자동으로 재실/퇴실 여부를 확인하여 상태값을 변경합니다.
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    {sensorStatusKeys.map((statusKey) => {
                      return (
                        <Grid item key={statusKey}>
                          <FormControlLabel
                            sx={{ fontSize: '14px' }}
                            value={statusKey}
                            control={<Radio />}
                            label={sensorStatusLabels[statusKey]}
                          />
                        </Grid>
                      );
                    })}

                    {data?.customStatuses.map((customStatus) => {
                      return (
                        <Grid item key={customStatus}>
                          <FormControlLabel
                            value={customStatus}
                            control={<Radio />}
                            label={customStatus}
                          />
                        </Grid>
                      );
                    })}
                  </Grid>
                </RadioGroup>
              </FormControl>
            </FormGroup>
          </>
        ) : null}
      </DialogContent>
      {isAdminMode ? (
        <DialogActions sx={{ margin: '40px 51px 45px 51px' }}>
          <LoadingButton
            sx={{ maxWidth: '235px', fontSize: '15px', height: '48px' }}
            fullWidth={true}
            variant={'contained'}
            color={'primary'}
            loading={isPutSensorDevicesLoading}
            onClick={handleExtraStatusesSave}
            disabled={!isEdited || isMemoOverflow}
          >
            저장하기
          </LoadingButton>
        </DialogActions>
      ) : (
        <Box sx={{ marginBottom: '45px' }}></Box>
      )}
      <Snackbar
        open={isSnackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={2000}
        message={`변경된 [${cardInfo?.companyUser?.name} ${cardInfo?.companyUser?.position}]룸의 정보가 반영되었습니다.`}
      />
      <CommonDialog
        open={isAlertModalOpen}
        maxWidth={'md'}
        fullScreen={false}
        fullWidth={true}
      >
        <DialogTitle
          id="knock-alert-dialog-title"
          sx={{ padding: '40px 30px' }}
        >
          {'룸 정보 수정을 취소하시겠습니까?'}
        </DialogTitle>
        <DialogContent sx={{ padding: '0 30px' }}>
          <DialogContentText id="knock-alert-dialog-description">
            <div>수정된 내용이 저장되지 않았습니다.</div>
            <div>수정을 취소하는 경우 이전 정보로 다시 노출됩니다.</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '20px 30px 40px 30px !important' }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                sx={{ height: '48px' }}
                fullWidth={true}
                variant={'contained'}
                onClick={handleAlertModalClose}
              >
                아니요
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{ height: '48px' }}
                fullWidth={true}
                variant={'contained'}
                onClick={handleClose}
                autoFocus
              >
                네
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </CommonDialog>
    </CommonDialog>
  );
}
