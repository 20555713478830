import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { useAppDispatch } from '../app/hooks';
import { api } from '../app/services/api';
import { CommonButton } from '../components/CommonButton';
import { CommonDialog } from '../components/CommonDialog';
import { CommonTextField } from '../components/CommonTextField';
import { DialogTitleWithCloseButton } from '../components/DialogTitleWithCloseButton';
import { authSlice } from '../features/Auth/authSlice';
import { ReactComponent as Logo } from '../logo.svg';
import { mainPagePath } from './MainPage';
import { SignInAdminPage } from './SignInAdminPage';

export const signInGuestPagePath = '/sign-in';
export function SignInGuestPage() {
  const dispatch = useAppDispatch();
  const [isAdminDialogOpen, setIsAdminDialogOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleGuestSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const companyCode = data.get('companyCode');
      if (companyCode) {
        dispatch(
          authSlice.actions.setCompanyCode(data.get('companyCode') as string),
        );
        navigate(mainPagePath);
      }
    },
    [dispatch, navigate],
  );

  const handleAdminModalClose = useCallback(() => {
    setIsAdminDialogOpen(false);
  }, []);

  useEffect(() => {
    dispatch(authSlice.actions.clearUserInfo());
    dispatch(api.util?.resetApiState());
  }, [dispatch]);

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box
        sx={{
          paddingTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Logo width={201} height={82.5} />
        <Box
          component="form"
          sx={{ mt: '30px' }}
          onSubmit={handleGuestSubmit}
          noValidate
        >
          <Grid container spacing={1} alignItems={'center'} sx={{ mb: '22px' }}>
            <Grid item>
              <CommonTextField
                margin="normal"
                required
                fullWidth
                id="companyCode"
                label="게스트 코드 입력"
                name="companyCode"
                autoFocus
                autoComplete={'companyCode'}
              />
            </Grid>
            <Grid item>
              <CommonButton type="submit" fullWidth variant="contained">
                {'>'}
              </CommonButton>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Link
                sx={{ cursor: 'pointer' }}
                variant="body2"
                onClick={() => {
                  setIsAdminDialogOpen(true);
                }}
              >
                {'관리자 로그인하기>'}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CommonDialog
        open={isAdminDialogOpen}
        onClose={handleAdminModalClose}
        maxWidth={'md'}
      >
        <DialogTitleWithCloseButton onClose={handleAdminModalClose}>
          관리자 로그인
        </DialogTitleWithCloseButton>
        <SignInAdminPage />
      </CommonDialog>
    </Container>
  );
}
