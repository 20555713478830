import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

import { ReactComponent as CloseIcon } from './CloseIcon.svg';

export interface CloseButtonProps {
  sx?: SxProps;
  onClick?: () => void;
}

export function CloseButton({ sx, onClick }: CloseButtonProps) {
  return (
    <Box
      sx={{ cursor: 'pointer', ...sx }}
      display={'inline-block'}
      onClick={onClick}
    >
      <CloseIcon width={24} height={24} fill={'#fff'} />
    </Box>
  );
}
