import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const CommonTextField = styled(TextField)(() => ({
  border: 0,
  margin: 0,
  boxShadow: 'none',
  '&>div': {
    border: 'none',
    boxShadow: 'none',
    fontSize: '14px',
    color: '#767676',
    height: '48px',
  },
  '& input': {
    padding: '16px 25px 15px 25px',
  },
}));
