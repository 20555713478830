import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactGridLayout, { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  sensorDevicesSelectors,
  useGetHomeQuery,
} from '../../app/services/api';
import { DotButtonPagination } from '../../components/DotButtonPagination';
import { RoomCard } from '../../components/RoomCard';
import { mediaBreakpoints } from '../../configs/mediaBreakpoints';
import { useIsAdminOrManagerMode } from '../../hooks/useIsAdminOrManagerMode';
import { SensorDevice } from '../../types/SensorDevice';
import { companySelectors, companySlice } from '../Company/companySlice';
import { roomCardsSlice } from './roomCardsSlice';

const cols = { xl: 5, lg: 5, md: 3, sm: 2, xs: 1 };
const ResponsiveGridLayout = WidthProvider(Responsive);
const cardHeight = 130;
const layoutStorageKey = '@@knock-layout@@';
const margin: [number, number] = [20, 20];
export function RoomCardsGrid() {
  // const isSmall = useIsSmall();
  // const isMobile = useIsMobile();
  const ref = useRef<null | HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { data } = useGetHomeQuery();
  const isAdminOrManagerMode = useIsAdminOrManagerMode();
  const { filterSensorStatus, viewport } = useAppSelector(
    companySelectors.selectState,
  );
  const [filteredSensorDevices, setFilteredSensorDevices] = useState<
    SensorDevice[]
  >([]);
  const [breakPoint, setBreakPoint] = useState<string | undefined>(undefined);
  const [itemsPerPage, setItemsPerPage] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const maxRows = useMemo(() => {
    if (viewport) {
      const height = viewport[1];
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const y = rect.top + window.scrollY;
        return Math.floor((height - y - 60) / (cardHeight + margin[1]));
      }
    }
    return 0;
  }, [viewport]);

  const [layouts, cards] = useMemo(() => {
    const layouts: ReactGridLayout.Layouts = {
      xl: [],
      lg: [],
      md: [],
      sm: [],
      xs: [],
    };

    const cards: JSX.Element[] = [];
    const handleEditClick = (cardInfo: SensorDevice) => () => {
      dispatch(roomCardsSlice.actions.setIsEditModalOpen(true));
      dispatch(roomCardsSlice.actions.setSelectedCardId(cardInfo.id));
    };

    filteredSensorDevices
      .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
      .forEach((sensorDevice, i) => {
        layouts.xl.push({
          h: 1,
          w: 1,
          i: sensorDevice.id,
          x: i % cols.xl,
          y: Math.floor(i / cols.xl),
        });
        layouts.lg.push({
          h: 1,
          w: 1,
          i: sensorDevice.id,
          x: i % cols.lg,
          y: Math.floor(i / cols.lg),
        });
        layouts.md.push({
          h: 1,
          w: 1,
          i: sensorDevice.id,
          x: i % cols.md,
          y: Math.floor(i / cols.md),
        });
        layouts.sm.push({
          h: 1,
          w: 1,
          i: sensorDevice.id,
          x: i % cols.sm,
          y: Math.floor(i / cols.sm),
        });
        layouts.xs.push({
          h: 1,
          w: 1,
          i: sensorDevice.id,
          x: i % cols.xs,
          y: Math.floor(i / cols.xs),
        });
        cards.push(
          <div key={sensorDevice.id}>
            <RoomCard
              isAdminOrManagerMode={isAdminOrManagerMode}
              value={sensorDevice}
              onEditClick={handleEditClick(sensorDevice)}
            />
          </div>,
        );
      });
    return [layouts, cards];
  }, [
    dispatch,
    isAdminOrManagerMode,
    currentPage,
    itemsPerPage,
    filteredSensorDevices,
  ]);

  const handleLayoutChange = useCallback(
    (currentLayout: ReactGridLayout.Layout[]) => {
      if (global.localStorage && data && filterSensorStatus === 'all') {
        global.localStorage.setItem(
          layoutStorageKey,
          JSON.stringify({
            [data.companyName]: currentLayout,
          }),
        );
      }
    },
    [data, filterSensorStatus],
  );

  const handleBreakpointChange = useCallback((newBreakpoint: string) => {
    setBreakPoint(newBreakpoint);
  }, []);

  useEffect(() => {
    if (breakPoint === 'xs') {
      setItemsPerPage(filteredSensorDevices.length);
    } else if (maxRows) {
      const itemsPerPage =
        maxRows * cols[(breakPoint || 'xl') as keyof typeof cols];
      setItemsPerPage(itemsPerPage);
    } else {
      setItemsPerPage(0);
    }
  }, [breakPoint, maxRows, filteredSensorDevices]);

  useEffect(() => {
    if (data) {
      let sensorDevices = sensorDevicesSelectors.selectAll(data);
      if (filterSensorStatus !== 'all') {
        sensorDevices = sensorDevices.filter((sensorDevice) => {
          return sensorDevice.status === filterSensorStatus;
        });
      }
      setFilteredSensorDevices(sensorDevices);
    } else {
      setFilteredSensorDevices([]);
    }
  }, [data, filterSensorStatus]);

  useEffect(() => {
    if (itemsPerPage > 0) {
      const pageSize = Math.ceil(filteredSensorDevices.length / itemsPerPage);
      setPageSize(pageSize);
    } else {
      setPageSize(0);
    }
  }, [filteredSensorDevices, itemsPerPage]);

  useEffect(() => {
    setCurrentPage(0);
  }, [filteredSensorDevices, itemsPerPage, pageSize]);

  useEffect(() => {
    dispatch(companySlice.actions.setIsClippedMode(breakPoint !== 'xs'));
  }, [dispatch, breakPoint]);

  return (
    <div ref={ref}>
      <ResponsiveGridLayout
        breakpoints={mediaBreakpoints}
        layouts={layouts}
        cols={cols}
        rowHeight={cardHeight}
        isResizable={false}
        isDraggable={false} // WidthProvider option
        isBounded={true}
        margin={margin}
        measureBeforeMount={false}
        compactType={'horizontal'} //horizontal로 설정해야 드래그 시 아래로 이동하는 것을 방지
        onLayoutChange={handleLayoutChange}
        onBreakpointChange={handleBreakpointChange}
      >
        {cards}
      </ResponsiveGridLayout>
      {pageSize > 1 ? (
        <div
          style={{
            position: 'absolute',
            bottom: breakPoint === 'xs' ? 10 : 30,
            left: '50%',
            transform: 'translateX(-50%)',
            margin: '0 auto',
            textAlign: 'center',
          }}
        >
          <DotButtonPagination
            pageSize={pageSize}
            currentPage={currentPage}
            onClick={(index) => setCurrentPage(index)}
          />
        </div>
      ) : null}
    </div>
  );
}
