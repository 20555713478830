import { EventMessage } from './EventMessage';
import { SensorStatus } from './SensorStatus';

/*
{
  "type": "SensorDeviceMessage",
  "id": "0001030002",
  "status": "휴가중",
  "auto": false,
  "companyUser": {
    "name": "최훈종",
    "position": "의원(팰릭스집)"
  },
  "memo": ""
}
 */

export interface SensorDeviceMessage extends EventMessage {
  type: 'SensorDeviceMessage';
  id: string;
  status: string | SensorStatus;
  auto: boolean;
  companyUser: { name: string; position: string };
  memo: string;
}

export function isSensorDeviceMessage(
  event: EventMessage,
): event is SensorDeviceMessage {
  return event.type === 'SensorDeviceMessage';
}
