import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SensorStatusWithAll } from '../../types/SensorStatus';

const name = 'companySlice';

export interface CompanyState {
  isHistoryModalOpen: boolean;
  isClippedMode: boolean;
  filterSensorStatus: SensorStatusWithAll;
  lastUpdatedAt?: string;
  viewport?: [number, number];
}

const initialState: CompanyState = {
  isClippedMode: true,
  filterSensorStatus: 'all',
  isHistoryModalOpen: false,
  viewport: undefined,
};

export const companySlice = createSlice({
  name,
  initialState,
  reducers: {
    setIsHistoryModalOpen: (state, action) => {
      state.isHistoryModalOpen = action.payload;
    },
    toggleIsHistoryModalOpen: (state) => {
      state.isHistoryModalOpen = !state.isHistoryModalOpen;
    },
    setFilterSensorStatus: (state, action) => {
      state.filterSensorStatus = action.payload;
    },
    setLastUpdatedAt: (state, action) => {
      state.lastUpdatedAt = action.payload;
    },
    setViewport: (state, action: PayloadAction<CompanyState['viewport']>) => {
      state.viewport = action.payload;
    },
    setIsClippedMode: (
      state,
      action: PayloadAction<CompanyState['isClippedMode']>,
    ) => {
      state.isClippedMode = action.payload;
    },
  },
});

export const companySelectors = {
  selectState: (state: any) => state[name] as CompanyState,
};
