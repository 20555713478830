import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from '../app/hooks';
import { api } from '../app/services/api';
import { authSlice } from '../features/Auth/authSlice';

export const signOutPagePath = '/sign-out';
export function SignOutPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(authSlice.actions.clearUserInfo());
    dispatch(api.util?.resetApiState());
    navigate('/sign-in');
  }, [dispatch, navigate]);

  return null;
}
