import React, { useCallback, useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { SensorDevice } from '../types/SensorDevice';
import { SensorStatus } from '../types/SensorStatus';
import { SensorStatusChip } from './SensorStatusChip';

const StyledCard = styled(Card)(
  ({
    status,
    isAdminOrManagerMode,
  }: {
    status: SensorStatus | null;
    isAdminOrManagerMode: boolean | undefined;
  }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    cursor: isAdminOrManagerMode ? 'pointer' : 'default',
    textAlign: 'center',
    boxShadow: '0 2px 4px 0 rgba(227, 227, 227, 0.5)',
    borderRadius: 0,
    backgroundColor: status === SensorStatus.OUT ? '#ebecef' : '#fff',
    border: status === SensorStatus.IN ? 'solid 1px #0ebc9a' : 'none',
  }),
);
export interface RoomCardProps {
  value: SensorDevice;
  isAdminOrManagerMode?: boolean;
  onEditClick?: (value: SensorDevice) => void;
}
export function RoomCard({
  value,
  isAdminOrManagerMode,
  onEditClick,
}: RoomCardProps) {
  const [hasMoved, setHasMoved] = useState(false);
  const [moveStartTime, setMoveStartTime] = useState(-1);
  const handleEditClick = useCallback(() => {
    if (isAdminOrManagerMode) onEditClick?.(value);
  }, [value, isAdminOrManagerMode, onEditClick]);
  const handleTouchStart = useCallback(() => {
    setHasMoved(false);
    setMoveStartTime(Date.now());
  }, []);
  const handleTouchMove = useCallback(() => {
    if (!hasMoved) {
      setHasMoved(true);
    }
  }, [hasMoved]);
  const handleTouchEnd = useCallback(() => {
    const isJustClick = Date.now() - moveStartTime < 100;
    if (!hasMoved || isJustClick) {
      handleEditClick();
    }
    setMoveStartTime(-1);
  }, [hasMoved, moveStartTime, handleEditClick]);
  return (
    <StyledCard
      isAdminOrManagerMode={isAdminOrManagerMode}
      status={value.status}
      onMouseDown={handleTouchStart}
      onMouseMove={handleTouchMove}
      onClick={handleTouchEnd}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <SensorStatusChip status={value.status as SensorStatus} />
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          sx={{ fontSize: '22px', margin: '12px 0 8px 0' }}
        >
          <span style={{ fontWeight: 'bold' }}>
            {value.companyUser?.name + ' '}
          </span>
          <span style={{ fontWeight: 500 }}>{value.companyUser?.position}</span>
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '-0.01px',
            color: '#a9a9a9',
          }}
          variant={'body2'}
          component={'p'}
        >
          {value.memo || ' '}
        </Typography>
      </CardContent>
    </StyledCard>
  );
}
