export type SensorDeviceHistorySearchType = 'ALL' | 'STATUS' | 'MEMO';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SensorDeviceHistorySearchType = {
  ALL: 'ALL' as SensorDeviceHistorySearchType,
  STATUS: 'STATUS' as SensorDeviceHistorySearchType,
  MEMO: 'MEMO' as SensorDeviceHistorySearchType,
};
export const sensorDeviceHistorySearchTypes: SensorDeviceHistorySearchType[] = [
  'ALL',
  'STATUS',
  'MEMO',
];
