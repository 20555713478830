import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert } from '@mui/lab';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Snackbar from '@mui/material/Snackbar';

import { useAppDispatch } from '../app/hooks';
import { api, usePostAuthenticateMutation } from '../app/services/api';
import { CommonButton } from '../components/CommonButton';
import { CommonTextField } from '../components/CommonTextField';
import { authSlice } from '../features/Auth/authSlice';
import { mainPagePath } from './MainPage';

export function SignInAdminPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen] = useState(false);
  const [
    postAuthenticate,
    { data: idTokenData, isLoading, isSuccess, isError },
  ] = usePostAuthenticateMutation();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    postAuthenticate({
      username: (data.get('username') as string) || '',
      password: (data.get('password') as string) || '',
    });
  };

  useEffect(() => {
    dispatch(authSlice.actions.clearUserInfo());
    dispatch(api.util?.resetApiState());
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess && idTokenData) {
      dispatch(authSlice.actions.setIdToken(idTokenData.idToken));
      navigate(mainPagePath);
    }
  }, [dispatch, idTokenData, navigate, isSuccess]);

  useEffect(() => {
    if (isError) {
      setIsErrorSnackbarOpen(true);
    }
  }, [isError]);

  const handleErrorSnackbarClose = useCallback(() => {
    setIsErrorSnackbarOpen(false);
  }, []);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          paddingTop: 8,
          paddingBottom: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <CommonTextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="관리자 ID"
            name="username"
            autoFocus
            autoComplete={'username'}
            sx={{ mb: '15px' }}
          />

          <CommonTextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="관리자 PW"
            type="password"
            id="password"
            autoComplete="current-password"
            sx={{ mb: '15px' }}
          />
          <CommonButton
            loading={isLoading}
            type="submit"
            fullWidth
            variant="contained"
          >
            로그인
          </CommonButton>
        </Box>
      </Box>
      <Snackbar
        open={isErrorSnackbarOpen}
        onClose={handleErrorSnackbarClose}
        autoHideDuration={2000}
      >
        <Alert
          onClose={handleErrorSnackbarClose}
          severity="warning"
          sx={{ width: '100%' }}
        >
          인증에 실패했습니다.
        </Alert>
      </Snackbar>
    </Container>
  );
}
