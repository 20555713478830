import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

const name = 'auth';

export interface AuthState {
  companyCode?: string;
  idToken?: string;
}

const initialState: AuthState = {
  idToken: undefined,
};

export const authSlice = createSlice({
  name,
  initialState,
  reducers: {
    setIdToken: (state, action: PayloadAction<string | undefined>) => {
      state.idToken = action.payload;
    },
    setCompanyCode: (state, action: PayloadAction<string | undefined>) => {
      state.companyCode = action.payload;
    },
    clearUserInfo: (state) => {
      state.idToken = undefined;
      state.companyCode = undefined;
    },
  },
});

export const authSelectors = {
  selectState: (state: RootState) => state[authSlice.name],
};
