import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  sensorDevicesSelectors,
  useGetHomeQuery,
  useGetSensorDeviceHistoriesMutation,
} from '../../app/services/api';
import { CommonDialog } from '../../components/CommonDialog';
import { CommonTextField } from '../../components/CommonTextField';
import { DialogTitleWithCloseButton } from '../../components/DialogTitleWithCloseButton';
import { HistoryTable } from '../../components/HistoryTable';
import { SensorDeviceHistory } from '../../types/SensorDeviceHistory';
import {
  SensorDeviceHistorySearchType,
  sensorDeviceHistorySearchTypes,
} from '../../types/SensorDeviceHistorySearchType';
import {
  roomCardsSelectors,
  roomCardsSlice,
} from '../RoomCards/roomCardsSlice';
import { companySelectors, companySlice } from './companySlice';

const filterStatusLabels: Record<SensorDeviceHistorySearchType, string> = {
  ALL: '전체',
  STATUS: '상태',
  MEMO: '메모',
};
const size = 20;
export function CompanyHistoryModal() {
  const dispatch = useAppDispatch();
  const { isHistoryModalOpen } = useAppSelector(companySelectors.selectState);
  const { selectedCardId } = useAppSelector(roomCardsSelectors.selectState);

  const [tempQuery, setTempQuery] = useState<string | undefined>(undefined);
  const [query, setQuery] = useState<string | undefined>(tempQuery);
  const [page, setPage] = useState(0);
  const [totalHistories, setTotalHistories] = useState<SensorDeviceHistory[]>(
    [],
  );
  const [sensorHistorySearchType, setSensorHistorySearchType] =
    useState<SensorDeviceHistorySearchType>('ALL');
  const [getSensorDeviceHistories, { data: historyItems }] =
    useGetSensorDeviceHistoriesMutation();

  const { data: homeData } = useGetHomeQuery();
  const nameMap = useMemo(() => {
    if (homeData) {
      return sensorDevicesSelectors.selectEntities(homeData);
    } else {
      return undefined;
    }
  }, [homeData]);

  const handleClose = useCallback(() => {
    dispatch(companySlice.actions.setIsHistoryModalOpen(false));
    dispatch(roomCardsSlice.actions.setSelectedCardId(undefined));
  }, [dispatch]);

  const handleQueryChange = useCallback(
    (event: { target: { value: React.SetStateAction<string> } }) => {
      setTempQuery(event.target.value as string);
    },
    [],
  );

  const handleQuerySearch = useCallback(() => {
    // setTotalHistories([]);
    setPage(0);
    setQuery(tempQuery);
  }, [tempQuery]);

  const handleFilterStatusChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSensorHistorySearchType(
        e.target.value as SensorDeviceHistorySearchType,
      );
    },
    [],
  );

  const handleSeeMore = useCallback(() => {
    setPage((page) => {
      return page + 1;
    });
  }, []);

  useEffect(() => {
    if (isHistoryModalOpen) {
      getSensorDeviceHistories({
        id: selectedCardId, // id가 있을 경우 한정
        searchKeyword: query,
        page,
        size,
        sensorHistorySearchType,
      });
    }
  }, [
    isHistoryModalOpen,
    selectedCardId,
    query,
    page,
    getSensorDeviceHistories,
    sensorHistorySearchType,
  ]);

  useEffect(() => {
    if (isHistoryModalOpen) {
      setSensorHistorySearchType('ALL');
      setPage(0);
    }
    setQuery(undefined);
    setTempQuery(undefined);
  }, [isHistoryModalOpen]);

  useEffect(() => {
    setPage(0);
  }, [selectedCardId, sensorHistorySearchType]);

  useEffect(() => {
    setQuery(undefined);
    setTempQuery(undefined);
  }, [selectedCardId]);

  useEffect(() => {
    if (historyItems) {
      setTotalHistories((totalHistories) => {
        return [...totalHistories.slice(0, size * page), ...historyItems];
      });
    }
  }, [historyItems, page]);

  return (
    <CommonDialog
      isMaxHeight={true}
      open={isHistoryModalOpen}
      onClose={handleClose}
      keepMounted={false}
    >
      <DialogTitleWithCloseButton title={''} onClose={handleClose}>
        <div>
          {selectedCardId && nameMap?.[selectedCardId]
            ? `${nameMap?.[selectedCardId]?.companyUser?.name} ${nameMap?.[selectedCardId]?.companyUser?.position} `
            : ''}
          업데이트 히스토리
        </div>
      </DialogTitleWithCloseButton>
      <DialogContent sx={{ marginBottom: '52px' }}>
        {selectedCardId ? null : (
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{ marginBottom: '18px' }}
          >
            <Grid item xs={10}>
              <CommonTextField
                fullWidth
                placeholder="검색어"
                variant="outlined"
                value={tempQuery}
                autoComplete={'off'}
                onChange={handleQueryChange}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleQuerySearch();
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                variant="contained"
                onClick={handleQuerySearch}
                sx={{ height: '48px' }}
              >
                검색
              </Button>
            </Grid>
          </Grid>
        )}
        <FormControl sx={{ margin: '0 0 12px 0' }}>
          <RadioGroup
            row={true}
            value={sensorHistorySearchType}
            onChange={handleFilterStatusChange}
          >
            {sensorDeviceHistorySearchTypes.map((status) => (
              <FormControlLabel
                value={status}
                key={status}
                control={<Radio />}
                label={filterStatusLabels[status]}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <HistoryTable
          nameMap={nameMap}
          items={totalHistories}
          onSeeMore={handleSeeMore}
          isShowMore={historyItems?.length === size}
        />
      </DialogContent>
    </CommonDialog>
  );
}
