import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import './App.css';
import { theme } from './configs/theme';
import { MainPage, mainPagePath } from './pages/MainPage';
import { SignInGuestPage, signInGuestPagePath } from './pages/SignInGuestPage';
import { SignOutPage, signOutPagePath } from './pages/SignOutPage';

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path={mainPagePath} element={<MainPage />} />
        <Route path={signInGuestPagePath} element={<SignInGuestPage />} />
        <Route path={signOutPagePath} element={<SignOutPage />} />
      </Routes>
    </ThemeProvider>
  );
}
export default App;
