import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuIcon from '@mui/icons-material/Menu';
import { SwipeableDrawer, useMediaQuery, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useGetAccountQuery, useGetHomeQuery } from '../../app/services/api';
import { CloseButton } from '../../components/CloseButton';
import { ShowEventHistoryButton } from '../../components/ShowEventHistoryButton';
import { TimeDisplay } from '../../components/TimeDisplay';
import { userRoleLabels } from '../../configs/userRoleLabels';
import { useIsAdminOrManagerMode } from '../../hooks/useIsAdminOrManagerMode';
import { signOutPagePath } from '../../pages/SignOutPage';
import { roomCardsSlice } from '../RoomCards/roomCardsSlice';
import { companySelectors, companySlice } from './companySlice';

const logoutLabel = '로그아웃';
const isNoAuth = process.env.REACT_APP_NO_AUTH === 'true';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#fff',
  boxShadow: 'none',
  [theme.breakpoints.down('md')]: {
    '& .MuiContainer-root': {
      minHeight: '58px !important',
      '& .MuiToolbar-regular': {
        minHeight: '58px !important',
      },
    },
  },
  [theme.breakpoints.up('md')]: {
    '& .MuiContainer-root': {
      minHeight: '78px !important',
      '& .MuiToolbar-regular': {
        minHeight: '78px !important',
      },
    },
  },
}));

export function CompanyAppBar() {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const isAdminOrManagerMode = useIsAdminOrManagerMode();
  const navigate = useNavigate();
  const { data } = useGetHomeQuery();
  const { lastUpdatedAt } = useAppSelector(companySelectors.selectState);
  const { data: user, isSuccess } = useGetAccountQuery();

  const [isDrawerMenuOpen, setIsDrawerMenuOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const usernameLabel = useMemo(() => {
    if (isSuccess) {
      if (user) {
        return (
          <>
            <span style={{ marginRight: '0.35em' }}>
              <b>{`${user?.lastName}${user?.firstName} ` || ''}</b>
            </span>
            <span>
              {user?.authorities && user?.authorities.length > 0
                ? userRoleLabels[user.authorities[0].name]
                : ''}
              님
            </span>
          </>
        );
      } else {
        return <span>{userRoleLabels['ROLE_GUEST']}</span>;
      }
    }
  }, [user, isSuccess]);

  const handleOpenDrawerMenu = useCallback(() => {
    setIsDrawerMenuOpen(true);
  }, []);

  const handleOpenUserMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    },
    [],
  );

  const handleCloseDrawerMenu = useCallback(() => {
    setIsDrawerMenuOpen(false);
  }, []);

  const handleCloseUserMenu = useCallback(() => {
    setAnchorElUser(null);
  }, []);

  const handleShowEventHistoryButtonClick = useCallback(() => {
    dispatch(roomCardsSlice.actions.setSelectedCardId(undefined));
    dispatch(companySlice.actions.setIsHistoryModalOpen(true));
  }, [dispatch]);

  const handleLogout = useCallback(() => {
    navigate(signOutPagePath);
  }, [navigate]);

  useEffect(() => {
    if (data) {
      dispatch(companySlice.actions.setLastUpdatedAt(data.updatedAt));
    }
  }, [dispatch, data]);

  return (
    <StyledAppBar
      position="static"
      sx={{
        backgroundColor: '#fff',
        boxShadow: 'none',
        '& .MuiContainer-root': {
          minHeight: matches ? '78px !important' : '58px !important',
        },
      }}
    >
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          {/*<Typography*/}
          {/*  variant="h6"*/}
          {/*  noWrap*/}
          {/*  component="a"*/}
          {/*  href="/"*/}
          {/*  sx={{*/}
          {/*    mr: 2,*/}
          {/*    display: { xs: 'none', md: 'flex' },*/}
          {/*    fontWeight: 700,*/}
          {/*    color: 'inherit',*/}
          {/*    textDecoration: 'none',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {data?.companyName || ''}*/}
          {/*</Typography>*/}
          {data?.companyLogo ? (
            <Box display={{ xs: 'none', md: 'flex' }}>
              <img
                src={`data:image/png;base64, ${data.companyLogo}`}
                height={48}
                alt={'company logo'}
              />
            </Box>
          ) : null}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              flexFlow: 'column',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                position: 'absolute',
                marginLeft: 'auto',
                marginRight: 'auto',
                left: 0,
                right: 0,
                textAlign: 'center',
              }}
            >
              <TimeDisplay />
            </Box>
          </Box>
          {isSuccess || isNoAuth ? (
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
              <Stack direction="row" spacing={2.5}>
                {lastUpdatedAt ? (
                  <Box visibility={isAdminOrManagerMode ? 'visible' : 'hidden'}>
                    <ShowEventHistoryButton
                      lastUpdatedAt={lastUpdatedAt}
                      onClick={handleShowEventHistoryButtonClick}
                    ></ShowEventHistoryButton>
                  </Box>
                ) : null}
                <Tooltip title="Open settings">
                  <Button
                    onClick={handleOpenUserMenu}
                    sx={{ p: 0, color: '#3d3d3d' }}
                  >
                    <>
                      <span style={{ marginRight: '.35em' }}>
                        {usernameLabel}
                      </span>
                      <ArrowDropDownIcon sx={{ color: '#b2b1b1' }} />
                    </>
                  </Button>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Typography textAlign="center" onClick={handleLogout}>
                      {logoutLabel}
                    </Typography>
                  </MenuItem>
                </Menu>
              </Stack>
            </Box>
          ) : null}

          <Box
            sx={{
              display: { xs: 'flex', md: 'none' },
              justifyContent: 'center',
              position: 'absolute',
              marginLeft: 'auto',
              marginRight: 'auto',
              left: 0,
              right: 0,
              textAlign: 'center',
            }}
          >
            {data?.companyLogo ? (
              <img
                src={`data:image/png;base64, ${data.companyLogo}`}
                height={38}
                alt={'company logo'}
              />
            ) : null}
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleOpenDrawerMenu}
            >
              <MenuIcon sx={{ color: '#756868' }} />
            </IconButton>
            <SwipeableDrawer
              anchor={'left'}
              open={isDrawerMenuOpen}
              onClose={handleCloseDrawerMenu}
              onOpen={handleOpenDrawerMenu}
            >
              <Box
                sx={{
                  minHeight: '100vh',
                  padding: '24px 32px 24px 28px',
                  backgroundColor: '#573bff',
                  color: '#fff',
                  fontSize: '14px',
                  overflowY: 'auto',
                }}
              >
                <Box>
                  <CloseButton
                    sx={{ color: '#fff' }}
                    onClick={handleCloseDrawerMenu}
                  />
                </Box>
                {isSuccess || isNoAuth ? (
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    marginTop={'24px'}
                  >
                    <Box>{usernameLabel}</Box>
                    <Box
                      onClick={handleLogout}
                      sx={{
                        fontWeight: 'bold',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {logoutLabel}
                    </Box>
                  </Stack>
                ) : null}
                {lastUpdatedAt ? (
                  <Box
                    marginTop={'20px'}
                    visibility={isAdminOrManagerMode ? 'inherit' : 'hidden'}
                  >
                    <ShowEventHistoryButton
                      fullWidth={true}
                      lastUpdatedAt={lastUpdatedAt}
                      onClick={handleShowEventHistoryButtonClick}
                    ></ShowEventHistoryButton>
                  </Box>
                ) : null}
              </Box>
            </SwipeableDrawer>
          </Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
}
