import React, { useCallback, useMemo } from 'react';

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  sensorDevicesSelectors,
  useGetHomeQuery,
} from '../../app/services/api';
import { CompanyStatItem } from '../../components/CompanyStatItem';
import { sensorStatusLabels } from '../../configs/sensorStatusLables';
import { SensorStatusWithAll } from '../../types/SensorStatus';
import { companySelectors, companySlice } from './companySlice';

const initStatKeys: SensorStatusWithAll[] = [
  SensorStatusWithAll.all,
  SensorStatusWithAll.IN,
  SensorStatusWithAll.OUT,
  SensorStatusWithAll.MEETING,
];
const statLabels: Record<SensorStatusWithAll, string> = {
  ...sensorStatusLabels,
  all: '전체',
};
const initStats: Record<SensorStatusWithAll, number> = {
  all: 0,
  MEETING: 0,
  IN: 0,
  OUT: 0,
};
export function CompanyStats() {
  const dispatch = useAppDispatch();
  const { filterSensorStatus } = useAppSelector(companySelectors.selectState);
  const { data } = useGetHomeQuery();
  const [statKeys, stats] = useMemo(() => {
    if (!data) {
      return [initStatKeys, { ...initStats }];
    }
    const keys = [...initStatKeys, ...data.customStatuses];
    const sensorDevices = sensorDevicesSelectors.selectAll(data);

    return [
      keys,
      sensorDevices.reduce(
        (acc, cur) => {
          if (cur.status) {
            if (acc[cur.status] === undefined) {
              acc[cur.status] = 0;
            }
            acc[cur.status] += 1;
          }
          acc.all += 1;
          return acc;
        },
        { ...initStats } as Record<SensorStatusWithAll | string, number>,
      ),
    ];
  }, [data]);

  const handleClickFactory = useCallback(
    (key: SensorStatusWithAll | string) => () => {
      dispatch(companySlice.actions.setFilterSensorStatus(key));
    },
    [dispatch],
  );
  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={2.5}
      maxWidth={'100%'}
      useFlexGap
      flexWrap="wrap"
    >
      {stats
        ? statKeys.map((key, i) => (
            <CompanyStatItem
              key={key}
              isSelected={filterSensorStatus === key}
              onClick={handleClickFactory(key)}
            >
              <span>{statLabels[key as SensorStatusWithAll] || key + ' '}</span>{' '}
              <span style={{ fontWeight: 'bold' }}>{stats[key] || '0'}</span>
              <span>명</span>
            </CompanyStatItem>
          ))
        : null}
    </Stack>
  );
}
