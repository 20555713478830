import { useMediaQuery, useTheme } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

export const StyledDialog = styled(Dialog)<{ isMaxHeight: boolean }>(
  ({ theme, isMaxHeight }) => ({
    '& .MuiDialogContent-root': {
      padding: '0 25px 0 35px ',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
      minHeight: isMaxHeight ? 'calc(100% - 65px)' : 'unset',
    },
  }),
);

export interface CommonDialogProps extends DialogProps {
  isMaxHeight?: boolean;
}

export function CommonDialog({
  children,
  isMaxHeight,
  ...props
}: CommonDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <StyledDialog
      maxWidth={'lg'}
      fullScreen={fullScreen}
      fullWidth={true}
      isMaxHeight={isMaxHeight || false}
      {...props}
    >
      {children}
    </StyledDialog>
  );
}
