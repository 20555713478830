import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const CompanyStatItem = styled(Paper)<{ isSelected: boolean }>(
  ({ theme, isSelected }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 'unset',
    boxShadow: 'unset',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '-0.01px',
    color: '#707070',
    padding: '0',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    textDecoration: isSelected ? 'underline' : 'unset',
  }),
);
