import React, { useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { useGetAccountQuery } from '../app/services/api';
import { CompanyAppBar } from '../features/Company/CompanyAppBar';
import { CompanyHistoryModal } from '../features/Company/CompanyHistoryModal';
import { CompanyStats } from '../features/Company/CompanyStats';
import {
  companySelectors,
  companySlice,
} from '../features/Company/companySlice';
import { RoomCards } from '../features/RoomCards/RoomCards';
import { signOutPagePath } from './SignOutPage';

const isNoAuth = process.env.REACT_APP_NO_AUTH === 'true';
export const mainPagePath = '/';
export function MainPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { width, height, ref } = useResizeDetector();
  const { isClippedMode } = useAppSelector(companySelectors.selectState);
  const { isError, isSuccess } = useGetAccountQuery();

  useEffect(() => {
    if (isError) {
      if (isNoAuth) return;
      else navigate(signOutPagePath);
    }
  }, [navigate, isError]);

  useEffect(() => {
    if (width && height) {
      dispatch(companySlice.actions.setViewport([width, height]));
    } else {
      dispatch(companySlice.actions.setViewport(undefined));
    }
  }, [dispatch, width, height]);

  return isNoAuth || isSuccess ? (
    <div
      className={`main-page-container${isClippedMode ? ' clipped' : ''}`}
      ref={ref}
    >
      <CompanyAppBar />
      <main>
        <Container
          sx={{ padding: '35px 4px', margin: 0 }}
          maxWidth={false}
          disableGutters={true}
        >
          <Box component={'div'} sx={{ marginLeft: '23px' }}>
            <CompanyStats />
          </Box>
          <RoomCards />
        </Container>
        <CompanyHistoryModal />
      </main>
    </div>
  ) : null;
}
