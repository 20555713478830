/*
자동모드
수동모드(재실)
수동모드(퇴실)
회의중

자동모드일때는 감지기 신호기반 알아서 변경

수동모드 던 회의중이던 자동 모드던 변경한 최종 데이터로 고정

( 자동일땐 자동
수동 재실/퇴실일때는 별도 변경전까지 그냥 수동 고정
회의중 도 마찬가지
 */
export type SensorStatus = 'OUT' | 'IN' | 'MEETING';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SensorStatus = {
  IN: 'IN' as SensorStatus,
  OUT: 'OUT' as SensorStatus,
  MEETING: 'MEETING' as SensorStatus,
};
export type SensorStatusWithAll = SensorStatus | 'all';
// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SensorStatusWithAll = {
  ...SensorStatus,
  all: 'all' as SensorStatusWithAll,
};
