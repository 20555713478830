import { createEntityAdapter } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { EventSourcePolyfill, NativeEventSource } from 'event-source-polyfill';

import { authSlice } from '../../features/Auth/authSlice';
import { signOutPagePath } from '../../pages/SignOutPage';
import { Company } from '../../types/Company';
import { CompanyNormalized } from '../../types/CompanyNormalized';
import { isDataMessage } from '../../types/DataMessage';
import { EventMessage } from '../../types/EventMessage';
import { SensorDevice } from '../../types/SensorDevice';
import { SensorDeviceHistory } from '../../types/SensorDeviceHistory';
import { SensorDeviceHistorySearchType } from '../../types/SensorDeviceHistorySearchType';
import { isSensorDeviceMessage } from '../../types/SensorDeviceMessage';
import { SensorStatus } from '../../types/SensorStatus';
import { User } from '../../types/User';
import { RootState } from '../store';

const EventSource = EventSourcePolyfill || NativeEventSource;
const testHeaders =
  process.env.REACT_APP_NO_AUTH === 'true' &&
  process.env.REACT_APP_TEST_COMPANY_CODE &&
  process.env.REACT_APP_TEST_USER_ID
    ? {
        'X-COMPANY-CODE': process.env.REACT_APP_TEST_COMPANY_CODE,
        'X-TEST-USER-ID': process.env.REACT_APP_TEST_USER_ID,
      }
    : undefined;

export const sensorDevicesAdapter = createEntityAdapter<SensorDevice>({
  selectId: (sensorDevice) => sensorDevice.id,
});

const baseUrlDomain =
  (process.env.STORYBOOK_URL_DOMAIN &&
    process.env.STORYBOOK_URL_DOMAIN === 'local') ||
  process.env.REACT_APP_DEPLOY_ENV === 'local'
    ? ''
    : process.env.REACT_APP_DEPLOY_ENV === 'prod'
    ? 'https://api.knock.place' // FIXME: prod 추가시 변경, 추후에 nginx 배포 설정 파일로 옮긴다.
    : 'https://stay.devnogari.com';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrlDomain}/api`,
    prepareHeaders: (headers, { getState }) => {
      const { idToken, companyCode } = (getState() as RootState)[
        authSlice.name
      ];
      // const csrfToken = new Cookies().get('csrftoken');
      //
      // headers.set('X-CSRFToken', csrfToken);
      if (idToken) {
        headers.set('Authorization', `Bearer ${idToken}`);
      } else if (companyCode) {
        headers.set('X-COMPANY-CODE', companyCode);
      }

      return headers;
    },
  }),
  tagTypes: ['Home', 'History'],
  endpoints: (builder) => ({
    postAuthenticate: builder.mutation<
      { idToken: string },
      { username: string; password: string }
    >({
      query: (body) => ({
        url: '/authenticate',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Home', 'History'],
    }),
    getAccount: builder.query<User | null, void>({
      query: () => ({
        url: '/account',
        method: 'GET',
      }),
      forceRefetch(params: {}) {
        return true;
      },
    }),
    getHome: builder.query<CompanyNormalized, void>({
      query() {
        return {
          url: `/home`,
          method: 'GET',
          headers: testHeaders
            ? { 'X-TEST-USER-ID': testHeaders['X-TEST-USER-ID'] }
            : undefined,
        };
      },
      forceRefetch(params: {}) {
        return true;
      },
      providesTags: ['Home'],
      transformResponse(response: Company) {
        if (process.env.REACT_APP_DEPLOY_ENV !== 'prod')
          console.debug(':Home:', response);
        return {
          ...response,
          sensorDevices: sensorDevicesAdapter.addMany(
            sensorDevicesAdapter.getInitialState(),
            response.sensorDevices,
          ),
        };
      },
      async onCacheEntryAdded(
        arg,
        {
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          getCacheEntry,
        },
      ) {
        let retryAttempt = 0;
        let eventSource: EventSource | undefined = undefined;
        //curl https://stay.devnogari.com/api/events -X GET -H 'X-COMPANY-CODE: stay'
        const connectEventSource = async (arg: {
          idToken?: string;
          companyCode?: string;
        }) => {
          const { idToken, companyCode } = arg;
          const eventSource = new EventSource(`${baseUrlDomain}/api/events`, {
            headers: idToken
              ? { Authorization: `Bearer ${idToken}` }
              : companyCode
              ? { 'X-COMPANY-CODE': companyCode }
              : testHeaders,
            withCredentials: false,
          }); // 주소 바꾸기
          try {
            eventSource.onmessage = (message) => {
              try {
                const data = JSON.parse(message.data) as EventMessage;
                if (process.env.REACT_APP_DEPLOY_ENV !== 'prod')
                  console.debug(':Event:', data);
                if (isDataMessage(data)) {
                  updateCachedData((draft) => {
                    draft.updatedAt = data.createdAt + 'Z'; // FIXME: iso 양식이지만, 한국 시간이 들어오고 있음
                    draft.sensorDevices = sensorDevicesAdapter.updateOne(
                      draft.sensorDevices,
                      {
                        id: data.id,
                        changes: {
                          status:
                            //@ts-ignore
                            data.signal === '20'
                              ? SensorStatus.IN
                              : //@ts-ignore
                              data.signal === '21'
                              ? SensorStatus.OUT
                              : data.signal,
                          // TODO: auto 상태도 업데이트 해야함
                        },
                      },
                    );
                  });
                } else if (isSensorDeviceMessage(data)) {
                  updateCachedData((draft) => {
                    draft.updatedAt = new Date().toISOString(); // TODO: 서버에서 받아오는 시간으로 변경
                    draft.sensorDevices = sensorDevicesAdapter.updateOne(
                      draft.sensorDevices,
                      {
                        id: data.id,
                        changes: {
                          status: data.status as SensorStatus,
                          auto: data.auto,
                          memo: data.memo,
                        },
                      },
                    );
                  });
                }
              } catch {}
            };

            eventSource.onerror = (error) => {
              if (process.env.REACT_APP_DEPLOY_ENV !== 'prod')
                console.debug(':Event:Error:', error);
              eventSource?.close();
              //@ts-ignore
              if (error?.status === 401) {
                // ConnectionEvent: status: 401, statusText: "Unauthorized"
                // 401 에러 발생시 재인증 유도
                eventSource?.close();
                window.location.replace(signOutPagePath);
                return;
              }
              retryAttempt += 1;
              const backOffTime = Math.pow(2, retryAttempt) * 1000;
              setTimeout(() => {
                connectEventSource({ idToken, companyCode });
              }, backOffTime);
            };
          } catch {}
          return eventSource;
        };
        await cacheDataLoaded;
        const { idToken, companyCode } = (getState() as RootState)[
          authSlice.name
        ];

        eventSource = await connectEventSource({ idToken, companyCode });
        await cacheEntryRemoved;
        eventSource?.close(); // FIXME: 캐쉬 제거 되었을 때 종료 하는 코드 추가
      },
    }),
    getSensorDeviceHistories: builder.mutation<
      SensorDeviceHistory[],
      {
        id?: string;
        page?: number;
        size?: number;
        searchKeyword?: string;
        sensorHistorySearchType: SensorDeviceHistorySearchType;
      }
    >({
      query: ({ id, ...params }) => ({
        url: id ? `/sensorDeviceHistories/${id}` : `/sensorDeviceHistories`,
        method: 'GET',
        params,
        headers: {
          'X-TEST-USER-ID': testHeaders?.['X-TEST-USER-ID'],
        },
      }),
    }),
    putSensorDevices: builder.mutation<
      SensorDevice,
      {
        id: string;
        auto?: boolean;
        status?: SensorStatus | string;
        memo?: string;
      }
    >({
      query: (body) => ({
        url: `/sensorDevices/${body.id}`,
        method: 'PUT',
        headers: {
          'X-TEST-USER-ID': testHeaders?.['X-TEST-USER-ID'],
        },
        body,
      }),
      invalidatesTags: ['Home', 'History'],
    }),
  }),
});

export const {
  usePostAuthenticateMutation,
  useGetAccountQuery,
  useGetHomeQuery,
  useGetSensorDeviceHistoriesMutation,
  usePutSensorDevicesMutation,
} = api;

export const sensorDevicesSelectors =
  sensorDevicesAdapter.getSelectors<CompanyNormalized>(
    (state) => state.sensorDevices,
  );
