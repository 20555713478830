import React, { ComponentProps } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

export interface DialogTitleWithCloseButtonProps
  extends ComponentProps<typeof DialogTitle> {
  children?: React.ReactNode;
  onClose: () => void;
}

export function DialogTitleWithCloseButton({
  children,
  onClose,
  ...other
}: DialogTitleWithCloseButtonProps) {
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: '32px 25px 21px 41px',
        borderBottom: 'none',
        position: 'relative',
      }}
      {...other}
    >
      <Grid container>
        <Grid
          item
          xs={11}
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            wordBreak: 'break-all',
          }}
        >
          {children}
        </Grid>
        <Grid item xs={1} sx={{ textAlign: 'right' }}>
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: '#333',
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    </DialogTitle>
  );
}
