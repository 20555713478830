import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

import { sensorStatusColors } from '../configs/sensorStatusColors';
import { sensorStatusLabels } from '../configs/sensorStatusLables';
import { SensorStatus } from '../types/SensorStatus';

export interface SensorStatusChipProps {
  status: SensorStatus | string;
  maxWidth?: number;
}

const StyledChip = styled(Chip)(
  ({ status, maxWidth }: SensorStatusChipProps) => {
    const commons = {
      borderRadius: '11.5px',
      fontSize: 14,
      fontWeight: 'bold',
      lineHeight: 1,
      letterSpacing: -0.01,
      height: 23,
      '> span': {
        padding: '2px 14px 0px',
      },
      maxWidth: maxWidth || 'unset',
    };
    if (
      status === 'MEETING' ||
      sensorStatusColors[status as SensorStatus] === undefined
    ) {
      return {
        ...commons,
        border: `solid 1px #0ebc9a`,
        backgroundColor: '#fff',
        color: sensorStatusColors.MEETING,
      };
    }
    return {
      ...commons,
      backgroundColor: sensorStatusColors[status as SensorStatus],
      color: '#fff',
    };
  },
);

export function SensorStatusChip({ status, ...props }: SensorStatusChipProps) {
  return (
    <StyledChip
      status={status}
      label={sensorStatusLabels[status as SensorStatus] || status}
      {...props}
    />
  );
}
