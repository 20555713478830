import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

const name = 'roomCardsSlice';

export interface RoomCardsState {
  isEditModalOpen: boolean;
  selectedCardId?: string;
  alignment: 'grid_10' | 'grid_20' | 'list';
}

const initialState: RoomCardsState = {
  isEditModalOpen: false,
  alignment: 'grid_10',
};

export const roomCardsSlice = createSlice({
  name,
  initialState,
  reducers: {
    toggleIsEditModalOpen: (state) => {
      state.isEditModalOpen = !state.isEditModalOpen;
    },
    setSelectedCardId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedCardId = action.payload;
    },

    setIsEditModalOpen: (state, action) => {
      state.isEditModalOpen = action.payload;
    },
    setAlignment: (state, action) => {
      state.alignment = action.payload;
    },
  },
});

export const roomCardsSelectors = {
  selectState: (state: RootState) => state[name],
};
