import { useMemo } from 'react';

import { useGetAccountQuery } from '../app/services/api';

export function useIsAdminOrManagerMode() {
  const { data, isSuccess } = useGetAccountQuery();
  return useMemo(() => {
    if (data && isSuccess) {
      return data.authorities.some((authority) => {
        return (
          authority.name === 'ROLE_ADMIN' || authority.name === 'ROLE_MANAGER'
        );
      });
    } else {
      return false;
    }
  }, [data, isSuccess]);
}
